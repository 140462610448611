import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-offline-hubs',
  templateUrl: './offline-hubs.component.html',
  styleUrls: ['./offline-hubs.component.scss'],
})
export class OfflineHubsComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { mac: hub.DS_MAC } });
  }
}
