import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HomeHTTPService } from 'src/app/services/home.http.service';

const TYPE_TOTAL_HUBS = 'total';
const TYPE_ONLINE_HUBS = 'online';
const TYPE_OFFLINE_HUBS = 'offline';
const TYPE_OUTDATED_HUBS = 'outdated';
const TYPE_SCHEDULED_HOMES = 'scheduled';

@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class AllComponent implements OnInit {
  currentPermissions = {
    read: false,
  };

  reportTypes = [
    {
      value: TYPE_TOTAL_HUBS,
      label: 'Total Hubs',
    },
    {
      value: TYPE_ONLINE_HUBS,
      label: 'Online Hubs',
    },
    {
      value: TYPE_OFFLINE_HUBS,
      label: 'Offline Hubs',
    },
    {
      value: TYPE_OUTDATED_HUBS,
      label: 'Outdated Hubs',
    },
    {
      value: TYPE_SCHEDULED_HOMES,
      label: 'Homes with schedules',
    },
  ];

  selectedReportType = '';

  isSearching = false;
  showError = false;
  errorMessage = '';
  isLoaded = false;

  items = [];

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private homeHttpService: HomeHTTPService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions = permissions.reports;

    if (!this.currentPermissions.read) {
      this.router.navigate(['/']);
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const filter = params['filter'];
      if (filter) {
        this.selectedReportType = filter;
        this.onSearch();
      }
    });
  }

  async onSearch() {
    if (!this.selectedReportType) {
      return this.showTimedError('Select first a report type before get one.');
    }

    this.isLoaded = false;
    this.isSearching = true;

    try {
      const result = await this.homeHttpService.getReports(this.selectedReportType);
      console.log(result.data.items);
      this.items = result.data.items;
      this.isLoaded = true;
      this.isSearching = false;
    } catch (error) {
      console.log(error);
      this.router.navigate(['/user-pages/logout']);
    }
  }

  onChangeType() {
    this.items = [];
  }

  showTimedError(message: string) {
    let self = this;

    this.errorMessage = message;
    this.showError = true;

    setTimeout(() => {
      self.showError = false;
      self.errorMessage = '';
    }, 5000);
  }
}
