import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FirmwareHTTPService } from 'src/app/services/firmware.http.service';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
})
export class FirmwaresComponent implements OnInit {
  firmwares = [];

  currentPermissions = {
    firmware: {
      create: false,
      delete: false,
      firmwareUpdater: false,
      read: false,
      update: false,
    },
  };

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private firmwareHttpService: FirmwareHTTPService
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions.firmware = permissions.firmware;

    if (!this.currentPermissions.firmware.read) {
      this.router.navigate(['/']);
    }

    this.loadData();
  }

  async loadData() {
    this.firmwares = [];

    try {
      const result = await this.firmwareHttpService.getAllFirmwaresMeta();

      const items = result.data.items.filter((item) => {
        return item.type !== 'removed';
      });
      this.firmwares = items;
    } catch (error) {
      console.log(error);
      this.router.navigate(['/user-pages/logout']);
    }
  }

  onEditFirmware(firmware) {
    localStorage.setItem('fmw_edit', JSON.stringify(firmware));
    this.router.navigate(['/firmware-updater/firmware-edit']);
  }

  onDownloadFirmware(firmware) {
    const url = `https://firmware.smith.aivity.com:9151/v1/firmware-binary/${firmware.id}`;

    window.open(url, '_blank');
  }

  async onDeleteFirmware(firmware) {
    const body = {
      type: 'removed',
      default: false,
    };

    try {
      await this.firmwareHttpService.updateFirmwareMeta(firmware.id, body);
      this.loadData();
    } catch (error) {
      console.log('erro ao deletar firmware', error);
    }
  }
}
