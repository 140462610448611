// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectHash: '-2ilttesmsrabrnwwgoeegnsnaq-dev',
  firmwareUrl: 'https://9o666i1dh5.execute-api.us-east-1.amazonaws.com/dev/v1',
  homeUrl: 'https://o1jtqija8j.execute-api.us-east-1.amazonaws.com/dev/v1',
  firmwareTypes: [
    {
      value: 'a',
      label: 'ALPHA'
    },
    {
      value: 'b',
      label: 'BETA',
    },
    {
      value: 'v',
      label: 'OFFICIAL'
    },
    {
      value: 'd',
      label: 'DEPRECATED'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
