import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HomeHTTPService } from 'src/app/services/home.http.service';
import * as moment from 'moment';
import { FirmwareHTTPService } from 'src/app/services/firmware.http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-detail',
  templateUrl: './home-detail.component.html',
  styleUrls: ['./home-detail.component.scss'],
})

export class HomeDetailComponent implements OnInit {
  homeName = '';
  homeDetail = null;

  currentPermissions = {
    homes: {
      read: false,
    },
    firmware: {
      firmwareUpdater: false,
    },
  };

  guests = [];
  hubs = [];
  scenarios = [];
  devices = [];
  rooms = [];
  schedules = [];
  deviceSchedules = [];
  firmwares = [];
  user: any = null;

  shades = [
    {
        "name": "Cellular Shades",
        "type": 0,
        "control": 0,
    },
    {
        "name": "Cellular Sky Light Shades",
        "type": 1,
        "control": 0,
    },
    {
        "name": "Cellular Day Night Shades",
        "type": 2,
        "control": 1,
    },
    {
        "name": "Cellular Top Down Bottom Up",
        "type": 3,
        "control": 1,
    },
    {
        "name": "Sheer Shadings",
        "type": 4,
        "control": 0,
    },
    {
        "name": "Roller Shades",
        "type": 5,
        "control": 0,
    },
    {
        "name": "Roman Shades",
        "subOption": "prior 9/21/2020",
        "type": 6,
        "control": 0,
    },
    {
        "name": "Roman Shades",
        "subOption": "after 9/21/2020",
        "type": 7,
        "control": 2,
    },
    {
        "name": "Solar Shades",
        "type": 8,
        "control": 0,
    },
    {
        "name": "Woven Wood Shades",
        "subOption": "prior 9/21/2020",
        "type": 10,
        "control": 0,
    },
    {
        "name": "Woven Wood Shades",
        "subOption": "after 9/21/2020",
        "type": 11,
        "control": 2,
    },
    {
        "name": "Wood Blinds",
        "type": 12,
        "control": 3,
    },
    {
        "name": "Drapes",
        "subOption": "Right",
        "type": 13,
        "control": 5,
    },
    {
        "name": "Drapes",
        "subOption": "Left",
        "type": 14,
        "control": 6,
    },
    {
        "name": "Drapes",
        "subOption": "Center",
        "type": 15,
        "control": 4,
    },

];

  constructor(
    private authService: AuthenticationService,
    private homeHTTPService: HomeHTTPService,
    private firmwareHTTPService: FirmwareHTTPService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions.homes = permissions.homes;
    this.currentPermissions.firmware = permissions.firmware;

    if (!this.currentPermissions.homes.read) {
      this.router.navigate(['/']);
    }

    const homeDetail = localStorage.getItem('home_det');

    if (!homeDetail) {
      this.router.navigate(['/homes']);
    }

    this.homeDetail = JSON.parse(homeDetail);

    this.homeName = this.homeDetail.NM_HOME;

    if (this.homeDetail.email) {
      this.homeName += ` - ${this.homeDetail.email}`;
    }
    if (this.homeDetail.macAddress) {
      this.homeName += ` - ${this.homeDetail.macAddress}`;
    }

    this.loadData();
  }

  async loadData() {
    try {
      this.guests = [];
      this.hubs = [];
      this.scenarios = [];
      this.devices = [];
      this.rooms = [];
      this.schedules = [];
      this.user = {};

      const resultFmrw = await this.firmwareHTTPService.getAllFirmwaresMeta();
      this.firmwares = resultFmrw.data.items;

      const result = await this.homeHTTPService.getHome(this.homeDetail.id);

      //console.log(`RESULT JSON`, JSON.stringify(result));

      this.user = result.data.user;
      this.rooms = result.data.rooms;
      this.hubs = result.data.boxes;
      this.guests = result.data.guests;
      this.scenarios = result.data.scenes;
      this.devices = result.data.devices;
      this.schedules = result.data.schedules;
      this.deviceSchedules = result.data.deviceSchedules;
      console.log(this.scenarios);

      this.schedules.forEach((schedule, idx) => {
        schedule.index = idx + 1;

        const scene = this.scenarios.find((scene) => {
          return scene.I_POSICAO == schedule.I_CENA_NUMERO;
        });

        if (scene) {
          schedule.sceneName = scene.NM_CENA;
        } else {
          schedule.sceneName = 'N/D';
        }
      });


      this.deviceSchedules.forEach((deviceSchedule, idx) => {
        deviceSchedule.index = idx + 1;

        const device = this.devices.find((device) => {
          return device.id === deviceSchedule.devicesID;
        });

        if (device) {
          deviceSchedule.deviceName = device.NM_DISPOSITIVO;
        } else {
          deviceSchedule.deviceName = 'N/D';
        }
      });

      this.schedules.sort(function (a, b) {
        if (a.I_POSICAO > b.I_POSICAO) {
          return 1;
        }
        if (a.I_POSICAO < b.I_POSICAO) {
          return -1;
        }
        return 0;
      });

      this.deviceSchedules.sort(function (a, b) {
        if (a.I_POSICAO > b.I_POSICAO) {
          return 1;
        }
        if (a.I_POSICAO < b.I_POSICAO) {
          return -1;
        }
        return 0;
      });

      this.checkHubsUpdated();
    } catch (error) {
      console.log(error);
    }
  }

  async checkHubsUpdated() {
    for (let i = 0; i < this.hubs.length; i++) {
      const hub = this.hubs[i];
      hub.updateAvailable = false;
      hub.isUpdating = false;

      try {
        const result = await this.firmwareHTTPService.checkHubUpdated(hub.DS_MAC);
        hub.updateAvailable = !result.data.updated;
      } catch (error) {
        console.log(error);
      }
    }
  }

  getString(str) {
    return JSON.stringify(str);
  }

  getShade(type) {
    const shade =  this.shades.find((element) => (element.type === type));
    //return `${shade.name}`
    return `${shade.name}${((shade.subOption)?` - ${shade.subOption}`:``)}`
  }

  async onUpdateHub(hub) {
    if (!hub) {
      return;
    }

    const firmwareDefault = this.firmwares.find((firmware) => {
      return firmware.type === 'v' && firmware.default;
    });

    try {
      hub.isUpdating = true;
      await this.firmwareHTTPService.startFirmwareUpdate(firmwareDefault.id, hub.DS_MAC);
      let self = this;

      setTimeout(() => {
        self.checkHubsUpdated();
        hub.isUpdating = false;
        hub.updateAvailable = false;
      }, 1000 * 120);
    } catch (error) {
      console.log(error);
      hub.isUpdating = false;
    }
  }

  async onExecuteScene(scene) {

    try {
      const result = await this.firmwareHTTPService.triggerScene(scene.boxesMAC, scene.I_POSICAO);
      this.toastService.success('Sent to the Hub', 'Command')
    } catch (error) {
      console.log(error);
      this.toastService.error('Error when executing scene. Try again later.', 'Command');
    }

  }

  convertDateFriendly(dateStr: string): string {
    return moment(dateStr).format('YYYY/MM/DD HH:mm');
  }
}
