import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HomeHTTPService } from 'src/app/services/home.http.service';

@Component({
  selector: 'app-homes',
  templateUrl: './homes.component.html',
  styleUrls: ['./homes.component.scss'],
})
export class HomesComponent implements OnInit {
  setups = [
    {
      value: 'ASSISTANT',
      label: 'Configured Smart Assistant',
    },
    {
      value: 'SCHEDULED',
      label: 'Configured Schedule',
    },
  ];

  selectedTypeFilter = 'EMAIL';
  selectedSetup = '';
  email = '';
  macAddress = '';

  searchTypes = [
    {
      value: 'EMAIL',
      label: 'Email',
    },
    {
      value: 'MAC_ADDRESS',
      label: 'MAC Address',
    },
  ];

  homes = [];

  currentPermissions = {
    read: false,
  };

  isSearching = false;
  showError = false;
  errorMessage = '';

  constructor(
    private authService: AuthenticationService,
    private homeHttpService: HomeHTTPService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions = permissions.homes;

    if (!this.currentPermissions.read) {
      this.router.navigate(['/']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      const mac = params['mac'];
      const email = params['email'];

      if (mac) {
        this.selectedTypeFilter = 'MAC_ADDRESS';
        this.macAddress = mac;
        this.onSearch();
      } else if (email) {
        this.selectedTypeFilter = 'EMAIL';
        this.email = email;
        this.onSearch();
      }
    });

  }

  async onSearch() {
    try {
      this.homes = [];

      if (this.selectedTypeFilter === 'EMAIL') {
        if (this.email === '') {
          return this.showTimedError('Please type the account e-mail before search.');
        }

        this.isSearching = true;

        const result = await this.homeHttpService.getHomesByEmail(this.email);
        this.homes = result.data.homes;
      } else if (this.selectedTypeFilter === 'MAC_ADDRESS') {
        if (this.macAddress === '') {
          return this.showTimedError('Please type the Hub MAC Address before search.');
        }

        this.isSearching = true;

        const result = await this.homeHttpService.getHomesByMacAddress(this.macAddress);
        this.homes = result.data.homes;
      }

      this.isSearching = false;
    } catch (error) {
      console.log(error);
      this.isSearching = false;
      if (error.status === 404) {
        this.showTimedError('Item not found.');
      } else {
        this.router.navigate(['/user-pages/logout']);
      }
    }
  }

  onHomeDetail(home) {
    home.email = this.email;
    home.macAddress = this.macAddress;
    localStorage.setItem('home_det', JSON.stringify(home));
    this.router.navigate(['/homes/detail']);
  }

  showTimedError(message) {
    let self = this;

    this.errorMessage = message;
    this.showError = true;

    setTimeout(() => {
      self.showError = false;
      self.errorMessage = '';
    }, 5000);
  }
}
