import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FirmwareHTTPService } from 'src/app/services/firmware.http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware-add',
  templateUrl: './firmware-add.component.html',
  styleUrls: ['./firmware-add.component.scss'],
})
export class FirmwareAddComponent implements OnInit {
  @ViewChild('dropzoneFile') dropzoneFile: NgxDropzoneComponent;

  model = {
    type: 'v',
    major: 1,
    minor: 0,
    rev: 0,
    hardware: 0,
    description: '',
    default: false,
  };

  firmwareTypes = [];

  resultBinary = null;
  savedMetadata = false;

  errorMessage = '';
  showErrorMetadata = false;
  isSavingMetadata = false;
  showErrorBinary = false;
  isSavingBinary = false;
  currentMetaId = '';

  currentPermissions = {
    firmware: {
      create: false,
      delete: false,
      firmwareUpdater: false,
      read: false,
      update: false,
    },
  };

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private firmwareHttpService: FirmwareHTTPService
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions.firmware = permissions.firmware;

    if (!this.currentPermissions.firmware.create) {
      this.router.navigate(['/']);
    }
    this.firmwareTypes = environment.firmwareTypes;
  }

  onFilesAdded(files: File[]) {
    console.log(files);

    var self = this;
    const ext = files[0].name.split('.').pop();

    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent) => {
        //application/octet-stream
        const file = new File([reader.result], 'file', { type: 'application/octet-stream', lastModified: Date.now() });
        self.resultBinary = file;
      };

      reader.readAsArrayBuffer(file);
    });
  }

  onFilesRejected(files: File[]) {
    this.showTimedBinaryError('Invalid file. Max. size must be 4MB and file extension .bin.');
  }

  async onSaveMetadata() {
    this.isSavingMetadata = true;

    try {
      this.model.major = parseInt(`${this.model.major}`);
      this.model.minor = parseInt(`${this.model.minor}`);
      this.model.rev = parseInt(`${this.model.rev}`);
      console.log(this.model);
      const result = await this.firmwareHttpService.addFirmwareMeta(this.model);
      this.currentMetaId = result.data.insertedIds[0];
      this.isSavingMetadata = false;
      this.savedMetadata = true;
    } catch (error) {
      this.isSavingMetadata = false;
      this.savedMetadata = false;
      console.log(JSON.parse(error._body));
      this.showTimedMetaError(JSON.parse(error._body).reason);
    }
  }

  async onSaveBinary() {
    if (!this.resultBinary) {
      return this.showTimedBinaryError('Drag and drop .bin firmware file or choose at least one to upload.');
    }

    if (!this.currentMetaId) {
      return this.showTimedBinaryError('Is mandatory you save metadata before the firmware binary.');
    }

    console.log(this.currentMetaId);

    try {
      const result = await this.firmwareHttpService.addFirmwareBinary(this.currentMetaId, this.resultBinary);
      console.log(result);
      this.router.navigate(['/firmware-updater/firmwares']);
    } catch (error) {
      console.log(error.response.data);

      if (error.response.status >= 401 && error.response.status <= 403) {
        this.router.navigate(['/user-pages/logout']);
      } else {
        this.showTimedBinaryError('Error when uploading binary. Try again later');
      }
    }
  }

  showTimedMetaError(message) {
    let self = this;

    this.errorMessage = message;
    this.showErrorMetadata = true;

    setTimeout(() => {
      self.showErrorMetadata = false;
      self.errorMessage = '';
    }, 5000);
  }

  showTimedBinaryError(message) {
    let self = this;

    this.errorMessage = message;
    this.showErrorBinary = true;

    setTimeout(() => {
      self.showErrorBinary = false;
      self.errorMessage = '';
    }, 5000);
  }
}
