import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirmwareHTTPService } from '../services/firmware.http.service';
import { HomeHTTPService } from '../services/home.http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  totalBoxes = 0;
  offlineBoxes = 0;
  onlineBoxes = 0;
  outdatedBoxes = 0;
  homesSchedule = 0;
  latestFirmwareProd = '0.0.0';

  constructor(private router: Router, private homeHttpService: HomeHTTPService, private firmwareHttpService: FirmwareHTTPService) {}

  ngOnInit() {
    this.loadData();
  }

  async loadData() {

    try {
      const result = await this.homeHttpService.getReportCounters();
      const dataFirmware = await this.firmwareHttpService.getLatestFirmwareProduction();
      this.totalBoxes = result.data.total;
      this.onlineBoxes = result.data.online;
      this.offlineBoxes = result.data.offline;
      this.outdatedBoxes = result.data.outdated;
      this.homesSchedule = result.data.scheduled;
      const firmware = dataFirmware.data;
      this.latestFirmwareProd = `${firmware.type}${firmware.major}.${firmware.minor}-${firmware.rev}`
    } catch (error) {
      console.log(error);
      this.router.navigate(['/user-pages/logout']);
    }

  }

  onClickComponent(filter: string) {
    this.router.navigate(['/reports/all'], { queryParams: { filter } });
  }

}
