import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FirmwareHTTPService } from 'src/app/services/firmware.http.service';

@Component({
  selector: 'app-firmware-updater',
  templateUrl: './firmware-updater.component.html',
  styleUrls: ['./firmware-updater.component.scss'],
})
export class FirmwareUpdaterComponent implements OnInit {
  useSpecificVersion = false;
  loaded = false;

  macAddress = '';

  isChecking = false;
  showErrorChecking = false;
  errorMessageChecking = '';
  isUpdating = false;
  showErrorUpdate = false;
  errorMessageUpdate = '';

  boxData: any = {
    hardware: null,
    firmware: null,
  };
  isOnline = false;
  isUpdated = false;

  firmwares = [];

  selectedFirmware = null;

  currentPermissions = {
    firmware: {
      firmwareUpdater: false,
    },
  };

  batchOutdated = {
    quantity: -1,
    online: -1,
    offline: -1,
  };

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private firmwareHttpService: FirmwareHTTPService,
    private toastService: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions.firmware = permissions.firmware;

    if (!this.currentPermissions.firmware.firmwareUpdater) {
      this.router.navigate(['/']);
    }

    this.loadData();
  }

  async loadData() {
    try {
      this.firmwares = [];
      const result = await this.firmwareHttpService.getAllFirmwaresMeta();
      this.firmwares = result.data.items;

      const resultOutdated = await this.firmwareHttpService.batchOudatedHubs(false);
      this.batchOutdated = resultOutdated.data;
    } catch (error) {
      console.log(error);
      this.router.navigate(['/user-pages/logout']);
    }
  }

  async onCheckFirmwareAvailable() {
    if (!this.macAddress) {
      return this.showTimedCheckingError('Type a valid MAC Address.');
    }

    this.isChecking = true;
    this.loaded = false;

    try {
      const result = await this.firmwareHttpService.checkHubUpdated(this.macAddress);

      this.isUpdated = result.data.updated;
      this.isOnline = result.data.online;

      this.boxData = {
        hardware: result.data.currentHardware,
        firmware: result.data.currentFirmware,
      };

      this.isChecking = false;
      this.loaded = true;
    } catch (error) {
      console.log(error);
      this.isChecking = false;
      this.loaded = false;
      this.showTimedCheckingError('Error getting hub status');
    }
  }

  enableStartButtonModal = true;

  async onStartBatchUpdate() {
    try {
      this.enableStartButtonModal = false;
      await this.firmwareHttpService.batchOudatedHubs(true);
      this.enableStartButtonModal = true;
      this.modalService.dismissAll();
      this.toastService.success('Process has been started. Wait some minutes and refresh page.', 'Success', { timeOut: 10000, progressBar: true })
    } catch (error) {
      console.log(error);
      this.enableStartButtonModal = true;
      this.toastService.error('An error occour when running batch updater. Try again later', 'Error', { timeOut: 10000, progressBar: true })
    }
  }

  async onClickUpdate() {
    if (!this.macAddress) {
      return this.showTimedCheckingError('Type a valid MAC Address.');
    }

    let id = '';

    if (this.selectedFirmware) {
      id = this.selectedFirmware;
    } else {
      const firmwareDefault = this.firmwares.find((firmware) => {
        return firmware.type === 'v' && firmware.default;
      });

      if (!firmwareDefault) {
        return this.showTimedUpdatingError('There are no firmwares available to download.');
      }

      id = firmwareDefault.id;
    }

    try {
      this.isUpdating = true;
      await this.firmwareHttpService.startFirmwareUpdate(id, this.macAddress);
      let self = this;

      setTimeout(() => {
        self.onCheckFirmwareAvailable();
        self.isUpdating = false;
      }, 1000 * 120);
    } catch (error) {
      console.log(error);
      this.isUpdating = false;
      this.showTimedUpdatingError('An error occour when starting firmware updater. Try again later');
    }
  }

  showTimedCheckingError(message: string) {
    let self = this;

    this.errorMessageChecking = message;
    this.showErrorChecking = true;

    setTimeout(() => {
      self.showErrorChecking = false;
      self.errorMessageChecking = '';
    }, 5000);
  }

  showTimedUpdatingError(message: string) {
    let self = this;

    this.errorMessageUpdate = message;
    this.showErrorUpdate = true;

    setTimeout(() => {
      self.showErrorUpdate = false;
      self.errorMessageUpdate = '';
    }, 5000);
  }

  openSmallModal(smallModalContent) {
    this.modalService.open(smallModalContent, { size: 'sm' });
  }
}
