import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-homes-schedules',
  templateUrl: './homes-schedules.component.html',
  styleUrls: ['./homes-schedules.component.scss'],
})
export class HomesSchedulesComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onHomeDetail(home) {
    home.email = 'By report';
    home.macAddress = '';
    localStorage.setItem('home_det', JSON.stringify(home));
    this.router.navigate(['/homes/detail']);
  }
}
