import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-online-hubs',
  templateUrl: './online-hubs.component.html',
  styleUrls: ['./online-hubs.component.scss']
})
export class OnlineHubsComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { mac: hub.DS_MAC } });
  }

}
