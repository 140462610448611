import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'report-total-hubs',
  templateUrl: './total-hubs.component.html',
  styleUrls: ['./total-hubs.component.scss'],
})
export class TotalHubsComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { mac: hub.DS_MAC } });
  }
}
