import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TodoComponent } from './apps/todo-list/todo/todo.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TodoListComponent } from './apps/todo-list/todo-list.component';
import { FirmwareUpdaterModule } from './firmware-updater/firmware-updater.module';
import { ReportsModule } from './reports/reports.module';
import { HomesModule } from './homes/homes.module';
import { UsersRegistredModule } from './users-registred/users-registred.module';
import { AuthenticationService } from './services/authentication.service';
import { HttpModule } from '@angular/http';
import { FirmwareHTTPService } from './services/firmware.http.service';
import { HomeHTTPService } from './services/home.http.service';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    TodoListComponent,
    TodoComponent,
    SpinnerComponent,
    ContentAnimateDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    FirmwareUpdaterModule,
    ReportsModule,
    HomesModule,
    UsersRegistredModule,
    ToastrModule.forRoot(), 
  ],
  providers: [ThemeService, AuthenticationService, FirmwareHTTPService, HomeHTTPService],
  bootstrap: [AppComponent]
})
export class AppModule { }
