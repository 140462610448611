import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-cognito',
  templateUrl: './app-users-cognito.component.html',
  styleUrls: ['./app-users-cognito.component.scss'],
})
export class UsersCognitoComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) { }

  ngOnInit(): void { }

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { email: hub.Attributes[1].Value } });
  }
}


// {
//   "Username": "000be71e-9a8f-4ccc-abb1-c0c0d54b24d5",
//   "Attributes": [
//     {
//       "Name": "name",
//       "Value": "Chuck Finch"
//     },
//     {
//       "Name": "email",
//       "Value": "chuck.finch@comcast.net"
//     }
//   ],
//   "UserCreateDate": "2022-01-25T19:23:36.958Z",
//   "UserLastModifiedDate": "2022-01-25T19:25:24.095Z",
//   "Enabled": true,
//   "UserStatus": "CONFIRMED"
// }
