import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HomeHTTPService } from 'src/app/services/home.http.service';

const TYPE_USER_APP = 'appUser';
const TYPE_USER_REGISTRED = 'registredUser';

@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class AllComponent implements OnInit {
  currentPermissions = {
    read: false,
  };

  reportTypes = [
    {
      value: TYPE_USER_APP,
      label: 'Smith Users',
    },
    {
      value: TYPE_USER_REGISTRED,
      label: 'Registred Users on Cognito',
    }
  ];

  selectedReportType = '';

  isSearching = false;
  showError = false;
  errorMessage = '';
  isLoaded = false;
  sortField = "NM_USUARIO";

  items = [];
  itemsCognito = [];

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private homeHttpService: HomeHTTPService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions = permissions.reports;

    if (!this.currentPermissions.read) {
      this.router.navigate(['/']);
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const filter = params['filter'];
      if (filter) {
        this.selectedReportType = filter;
        this.onSearch();
      } 
    });
  }

  async onSearch() {
    if (!this.selectedReportType) {
      return this.showTimedError('Select first a report type before get one.');
    }

    this.isLoaded = false;
    this.isSearching = true;

    try {
      let result;

      if (this.selectedReportType == TYPE_USER_REGISTRED ) {
        result = await this.homeHttpService.getUsersCognito('');
        this.itemsCognito = (result?.data?.users).sort(function (a, b) {
          if (a.Attributes[0].Value  > b.Attributes[0].Value ) {
            return 1;
          }
          if (a.Attributes[0].Value  < b.Attributes[0].Value ) {
            return -1;
          }
          return 0;
        });
      } else {
        result = await this.homeHttpService.getUsersRegistred('');
        this.items = (result?.data?.users).sort(function (a, b) {
          if (a[`NM_USUARIO`] > b[`NM_USUARIO`]) {
            return 1;
          }
          if (a[`NM_USUARIO`] < b[`NM_USUARIO`]) {
            return -1;
          }
          return 0;
        });
      }

      //console.log('USERS', JSON.stringify(result?.data?.users));
      
      this.isLoaded = true;
      this.isSearching = false;
    } catch (error) {
      console.log(error);
      this.router.navigate(['/user-pages/logout']);
    }
  }

  onChangeType() {
    //this.items = [];
  }

  showTimedError(message: string) {
    let self = this;

    this.errorMessage = message;
    this.showError = true;

    setTimeout(() => {
      self.showError = false;
      self.errorMessage = '';
    }, 5000);
  }
}
