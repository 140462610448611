import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HubsOutdatedComponent } from './hubs-outdated/hubs-outdated.component';
import { AllComponent } from './all/all.component';
import { TotalHubsComponent } from './total-hubs/total-hubs.component';
import { OnlineHubsComponent } from './online-hubs/online-hubs.component';
import { OfflineHubsComponent } from './offline-hubs/offline-hubs.component';
import { HomesSchedulesComponent } from './homes-schedules/homes-schedules.component';
import { HomesAssistantsComponent } from './homes-assistants/homes-assistants.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: 'all', component: AllComponent },
]


@NgModule({
  declarations: [HubsOutdatedComponent, AllComponent, TotalHubsComponent, OnlineHubsComponent, OfflineHubsComponent, HomesSchedulesComponent, HomesAssistantsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
  ]
})
export class ReportsModule { }
