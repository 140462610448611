import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, RequestOptions } from '@angular/http';

import 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class HomeHTTPService {
  private server: string = environment.homeUrl;

  public constructor(private _http: Http, private authService: AuthenticationService) {}

  getHomes(): Promise<any> {
    const url = `${this.server}/homes`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getHomesByMacAddress(macAddress: string): Promise<any> {
    const url = `${this.server}/homes/search-mac/${macAddress}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    //console.log(`Token`, this.authService.returnToken());
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getHomesByEmail(email: string): Promise<any> {
    const url =  encodeURI(`${this.server}/homes/search-email/${email}`);

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getHome(homeId: string): Promise<any> {
    const url = `${this.server}/home/${homeId}/properties`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getReports(type: string): Promise<any> {
    const url = `${this.server}/reports/${type}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getUsersRegistred(type: string): Promise<any> {
    const url = `${this.server}/users`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getUsersCognito(type: string): Promise<any> {
    const url = `${this.server}/users/cognito`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getReportCounters(): Promise<any> {
    const url = `${this.server}/report-counters`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }
}
