import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'report-outdated',
  templateUrl: './hubs-outdated.component.html',
  styleUrls: ['./hubs-outdated.component.scss'],
})
export class HubsOutdatedComponent implements OnInit {
  @Input() items: any[];

  hubs = [
    {
      macAddress: '705040203010',
      networkStatus: 'online',
      email: 'higor@aivity.com',
    },
    {
      macAddress: '705020300011',
      networkStatus: 'offline',
      email: 'andre@aivity.com',
    },
    {
      macAddress: '001122334455',
      networkStatus: 'offline',
      email: 'pedro@minharedacao.com.br',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { mac: hub.DS_MAC } });
  }
}
