import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AllComponent } from './all/all.component';
import { UsersAppComponent } from './app-users/app-users.component';
import { UsersCognitoComponent } from './app-users-cognito/app-users-cognito.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: '', component: AllComponent },
  { path: 'all', component: AllComponent },
]

@NgModule({
  declarations: [AllComponent, UsersAppComponent, UsersCognitoComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
  ]
})
export class UsersRegistredModule { }
