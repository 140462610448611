import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, RequestOptions } from '@angular/http';

import 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import * as FormData from 'form-data';
import axios from 'axios';

export interface IFirmwareMetaUpdate {
  type: string;
  default: boolean;
}

export interface IFirmware {
  major: number;
  minor: number;
  rev: number;
}

export interface IFirmwareMeta extends IFirmware {
  type: string;
  hardware: number;
  description: string;
  default: boolean;
}

@Injectable()
export class FirmwareHTTPService {
  private server: string = environment.firmwareUrl;

  public constructor(private _http: Http, private authService: AuthenticationService) {}

  getAllFirmwaresMeta(): Promise<any> {
    const url = `${this.server}/firmwares-meta`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  getSpecificFirmwareMeta(firmwareId: string): Promise<any> {
    const url = `${this.server}/firmware-meta/${firmwareId}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  addFirmwareMeta(body: IFirmwareMeta): Promise<any> {
    const url = `${this.server}/firmware-meta`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    options.body = body;

    return this._http
      .post(url, body, options)
      .map((data) => data.json())
      .toPromise();
  }

  addFirmwareBinary(metaId: string, binary: any): Promise<any> {
    let hdrs: Headers = new Headers();
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    const url = `https://firmware.smith.aivity.com:9151/v1/firmware-binary/${metaId}`;

    const data = new FormData();
    data.append('file', binary);

    console.log(data);
    return axios({
      method: 'post',
      url,
      headers: {
        Authorization: this.authService.returnToken(),
      },
      data: data,
    });
  }

  checkFirmwareAvailable(body: IFirmware): Promise<any> {
    const url = `${this.server}/firmware-meta`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    options.body = body;

    return this._http
      .post(url, body, options)
      .map((data) => data.json())
      .toPromise();
  }

  updateFirmwareMeta(metaId: string, body: IFirmwareMetaUpdate): Promise<any> {
    const url = `${this.server}/firmware-meta/${metaId}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    options.body = body;

    return this._http
      .put(url, body, options)
      .map((data) => data.json())
      .toPromise();
  }

  checkHubUpdated(macAddress: string): Promise<any> {
    const url = `${this.server}/firmware-check-available/${macAddress}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  batchOudatedHubs(startProcess: boolean = false): Promise<any> {
    const url = `${this.server}/batch-update?startProcess=${startProcess}`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }

  startFirmwareUpdate(id: string, macAddress: string): Promise<any> {
    const url = `${this.server}/firmware-start`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    const payload = {
      macAddress,
      id,
    };

    options.body = payload;

    return this._http
      .post(url, payload, options)
      .map((data) => data.json())
      .toPromise();
  }

  triggerScene(macAddress: string, sceneId: number): Promise<any> {
    const url = `${this.server}/send-command`;

    const seqNumber = Math.floor(Math.random() * 250)
      .toString(16)
      .padStart(2, '0');

    const sceneNumber = sceneId.toString(16).padStart(2, '0');

    const data = `001B00000000000000${macAddress}02${seqNumber}000000000D04000100${sceneNumber}`.toUpperCase();

    console.log('Scene Number', sceneNumber, data);

    const body = {
      macAddress,
      data,
    };

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', '5200a1a44c114a2233a3');

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    options.body = body;

    return this._http
      .post(url, body, options)
      .map((data) => data.json())
      .toPromise();
  }

  getLatestFirmwareProduction(): Promise<any> {
    const url = `${this.server}/latest`;

    let hdrs: Headers = new Headers();
    hdrs.append('Content-Type', 'application/json');
    hdrs.set('Authorization', this.authService.returnToken());

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    return this._http
      .get(url, options)
      .map((data) => data.json())
      .toPromise();
  }
}
