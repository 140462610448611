import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FirmwareHTTPService } from 'src/app/services/firmware.http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware-edit',
  templateUrl: './firmware-edit.component.html',
  styleUrls: ['./firmware-edit.component.scss'],
})
export class FirmwareEditComponent implements OnInit {
  model = {
    major: 0,
    minor: 0,
    rev: 0,
    type: 'a',
    default: false,
    id: '',
  };

  errorMessage = '';
  showErrorMetadata = false;
  isSavingMetadata = false;

  firmwareTypes = [];

  currentPermissions = {
    firmware: {
      create: false,
      delete: false,
      firmwareUpdater: false,
      read: false,
      update: false,
    },
  };

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private firmwareHttpService: FirmwareHTTPService
  ) {}

  ngOnInit(): void {
    const permissions = this.authService.getAuthenticatedPermissions();

    this.currentPermissions.firmware = permissions.firmware;

    if (!this.currentPermissions.firmware.update) {
      this.router.navigate(['/']);
    }

    this.firmwareTypes = environment.firmwareTypes;

    this.loadData();
  }

  loadData() {
    const fmwEdit = localStorage.getItem('fmw_edit');

    if (!fmwEdit) {
      return this.router.navigate(['/firmware-updater/firmwares']);
    }

    const firmware = JSON.parse(fmwEdit);

    this.model = firmware;
  }

  async onSubmit() {
    try {
      this.isSavingMetadata = true;
      await this.firmwareHttpService.updateFirmwareMeta(this.model.id, this.model);
      return this.router.navigate(['/firmware-updater/firmwares']);
    } catch (error) {
      console.log(error);
      this.isSavingMetadata = false;
      if (error.response.status >= 401 && error.response.status <= 403) {
        this.router.navigate(['/user-pages/logout']);
      } else {
        this.showTimedMetaError('Error when uploading binary. Try again later');
      }
    }
  }

  showTimedMetaError(message) {
    let self = this;

    this.errorMessage = message;
    this.showErrorMetadata = true;

    setTimeout(() => {
      self.showErrorMetadata = false;
      self.errorMessage = '';
    }, 5000);
  }
}
