import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public uiReportCollapsed = false;
  public uiFirmwareCollapsed = false;
  public samplePagesCollapsed = false;
  public name = '';
  public production = true;

  permissions: any = {
    homes: {
      read: false,
    },
    usersRegistred: {
      read: false,
    },
    firmware: {
      read: false,
      firmwareUpdater: false,
    },
    reports: {
      read: false,
    },
    users: {
      read: false,
    }
  }

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    const body = document.querySelector('body');

    const authData = this.authService.getAuthenticatedData();
    if (authData)
      this.name = authData.fullName;
    if (this.authService.getAuthenticatedPermissions())
      this.permissions = this.authService.getAuthenticatedPermissions();

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
