import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Headers, RequestOptions, BaseRequestOptions } from '@angular/http';

import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private server: string = "https://1w6pv7xo3m.execute-api.us-east-1.amazonaws.com/prod/v1";

  constructor(private _http: Http) {
  }

  signIn(userAttributes): Promise<any> {

    var url = `${this.server}/auth`;

    let hdrs: Headers = new Headers();
    hdrs.append("Content-Type", "application/json");

    let options: RequestOptionsArgs = new RequestOptions();
    options.headers = hdrs;

    options.body = userAttributes;

    return this._http.post(url, userAttributes, options)
      .map(data => data.json()).toPromise();

  }

  clearAuthData() {

    localStorage.clear();

  }

  setData(user, token) {
    localStorage.setItem('smith', JSON.stringify(user));
    localStorage.setItem('smith-token', token);
  }

  checkTokenValidate() {

    return (localStorage.getItem("smith") != null)

  }

  returnToken() {
    return localStorage.getItem('smith-token');
  }

  isAuthenticated() {
    return this.checkTokenValidate()
  }

  getAuthenticatedData() {

    return JSON.parse(localStorage.getItem('smith'));

  }

  getAuthenticatedPermissions() {

    if (localStorage.getItem('smith')) {
      const data = JSON.parse(localStorage.getItem('smith'));

      return JSON.parse(data.permissions);
    }

    return null;
    

  }

  encPassword: string = "@@f1a0c1a1d5e1#1d2e1f0c9a5!88";
}
