import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirmwareUpdaterComponent } from './firmware-updater/firmware-updater.component';
import { RouterModule, Routes } from '@angular/router';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { FirmwareAddComponent } from './firmware-add/firmware-add.component';
import { FirmwareEditComponent } from './firmware-edit/firmware-edit.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: '', component: FirmwareUpdaterComponent },
  { path: 'firmwares', component: FirmwaresComponent },
  { path: 'firmware-add', component: FirmwareAddComponent },
  { path: 'firmware-edit', component: FirmwareEditComponent }
]

@NgModule({
  declarations: [FirmwareUpdaterComponent, FirmwaresComponent, FirmwareAddComponent, FirmwareEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    NgxDropzoneModule,
  ]
})
export class FirmwareUpdaterModule { }
