import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss'],
})
export class UsersAppComponent implements OnInit {
  @Input() items: any[];

  constructor(private router: Router) { }

  ngOnInit(): void { }

  openHomes(hub) {
    this.router.navigate(['/homes'], { queryParams: { email: hub.DS_EMAIL } });
  }
}


// { "__typename": "User", 
// "_lastChangedAt": 1644078619096, 
// "NM_USUARIO": "Carlton Chen", 
// "URL_FOTO": null, 
// "createdAt": "2022-02-05T16:30:19.076Z", 
// "DS_EMAIL": "0123csc@gmail.com", 
// "TYPE": "Homeowner", 
// "DS_LOGIN": "0123csc@gmail.com", 
// "_version": 1, 
// "userID": "c84c4581-894b-4dc1-ac22-06391991c920", "updatedAt": "2022-02-05T16:30:19.076Z", "id": "e2f4f6b9-93a5-4fe4-8de8-57d92f73b01a" },